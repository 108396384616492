import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import { Layout } from "../components/common"
import { MetaData } from "../components/common/meta";
import SocialIcons from "../components/common/SocialIcons";
import ContactForm from "../components/sections/contact-form";

import "../styles/pages/page-shopifyapps.scss";

const PageShopifyApps = ({ location }) => {
	const data = useStaticQuery(graphql`{
		ghostPage: ghostPage(tags: {elemMatch: {name: {eq: "#page-shopifyapps"}}}) {
      ...GhostPageFields
    }
		im1: file(relativePath: {eq: "shopifyapps/im1.jpg"}) {
			childImageSharp {
				gatsbyImageData(quality: 100, layout: FULL_WIDTH)
			}
		}
		im2: file(relativePath: {eq: "shopifyapps/im2.jpg"}) {
			childImageSharp {
				gatsbyImageData(quality: 100, layout: FULL_WIDTH)
			}
		}
		im3: file(relativePath: {eq: "shopifyapps/im3.jpg"}) {
			childImageSharp {
				gatsbyImageData(quality: 100, layout: FULL_WIDTH)
			}
		}
		skroutz: file(relativePath: {eq: "shopifyapps/skroutz.png"}) {
			childImageSharp {
				gatsbyImageData(quality: 100, layout: FULL_WIDTH)
			}
		}
		glami: file(relativePath: {eq: "shopifyapps/glami.png"}) {
			childImageSharp {
				gatsbyImageData(quality: 100, layout: FULL_WIDTH)
			}
		}
		shopflix: file(relativePath: {eq: "shopifyapps/shopflix.png"}) {
			childImageSharp {
				gatsbyImageData(quality: 100, layout: FULL_WIDTH)
			}
		}
		galaxyStore: file(relativePath: {eq: "shopifyapps/galaxystore.png"}) {
			childImageSharp {
				gatsbyImageData(quality: 100, layout: FULL_WIDTH)
			}
		}
		softOne: file(relativePath: {eq: "shopifyapps/softone.png"}) {
			childImageSharp {
				gatsbyImageData(quality: 100, layout: FULL_WIDTH)
			}
		}
		atlantis: file(relativePath: {eq: "shopifyapps/atlantis.png"}) {
			childImageSharp {
				gatsbyImageData(quality: 100, layout: FULL_WIDTH)
			}
		}
		pylon: file(relativePath: {eq: "shopifyapps/pylon.png"}) {
			childImageSharp {
				gatsbyImageData(quality: 100, layout: FULL_WIDTH)
			}
		}
		boxNow: file(relativePath: {eq: "shopifyapps/boxnow.png"}) {
			childImageSharp {
				gatsbyImageData(quality: 100, layout: FULL_WIDTH)
			}
		}
		easyMail: file(relativePath: {eq: "shopifyapps/easymail.png"}) {
			childImageSharp {
				gatsbyImageData(quality: 100, layout: FULL_WIDTH)
			}
		}
		acs: file(relativePath: {eq: "shopifyapps/acs.png"}) {
			childImageSharp {
				gatsbyImageData(quality: 100, layout: FULL_WIDTH)
			}
		}
		geniki: file(relativePath: {eq: "shopifyapps/geniki.png"}) {
			childImageSharp {
				gatsbyImageData(quality: 100, layout: FULL_WIDTH)
			}
		}
		speedex: file(relativePath: {eq: "shopifyapps/speedex.png"}) {
			childImageSharp {
				gatsbyImageData(quality: 100, layout: FULL_WIDTH)
			}
		}
	}`);

	return (
		<>
			<MetaData
				data={data}
				location={location}
				type="website"
			/>
			<Layout>
				<article className="page-shopify-apps">
					<header className="page-header background__theme--gradient mb-5 pb-5">
						<div className="container">
							<div className="row">
								<div className="col text-align-right">
									<SocialIcons type="light" />
								</div>
							</div>
							<div className="row align-items-center">
								<div className="col-md-6 order-1 order-md-0">
									<div className="px-4">
										<h1 className="header__title mb-0">
											Έχεις VOUCHER ψηφιακής ενισχυσης;
										</h1>
										<p className="header__subtitle h4 mb-0">
											Έχεις την πιο ολοκληρωμένη λύση από την Think Plus.
										</p>
										<p className="header__text h5 my-5 py-3">
											Εδώ θα υλοποιήσεις ένα ολοκληρωμένο σύστημα διασύνδεσης του καταστήματος ηλεκτρονικού εμπορίου σου, με κάθε marketplace, ERP, Courier ή Locker Service της Ελλάδας, πάντα ανάλογα με τις ανάγκες και τους στόχους της δικής σου eCommerce επιχείρησης.
										</p>
										<Link to="#contact_form_section" className="btn btn__light--inverted">
											Eπικοινώνησε άμεσα!
										</Link>
									</div>
								</div>
								<div className="col-md-6">
									<GatsbyImage
										image={data.ghostPage.localFeatureImage.childImageSharp.gatsbyImageData}
										className="header__image my-5 mx-auto"
										alt="Shopify integrations"
									/>
								</div>
							</div>
						</div>
					</header>
					<section className="container mb-5" style={{ maxWidth: 1165 }}>
						<div className="row align-items-center mb-5">
							<div className="col-md-5">
								<GatsbyImage
									image={data.im1.childImageSharp.gatsbyImageData}
									className="mb-3"
									alt=""
								/>
							</div>
							<div className="col-md-7">
								<p>Το οικοσύστημα SYN αφορά στον κλάδο του ηλεκτρονικού εμπορίου και πρόκειται για ένα <strong>εύχρηστο σύνολο εφαρμογών</strong>, σκοπός του οποίου είναι να απλοποιεί, όσο το δυνατόν περισσότερο, τη διαδικασία καταχώρησης των προϊόντων των ηλεκτρονικών καταστημάτων στις <strong>μεγαλύτερες διαδικτυακές αγορές</strong>. Με τις εφαρμογές του SYN, τα σύγχρονα Shopify e-shop θα μπορούν να διασυνδέουν τα προϊόντα ή τις υπηρεσίες τους, με <strong>όποια ηλεκτρονική αγορά (marketplace)</strong> ή ιστοσελίδα σύγκρισης τιμών επιθυμούν, για να διευρύνουν το πελατολόγιό τους και να αναπτυχθούν σε νέα κοινά. Επιπλέον, μπορούν να διασυνδέονται με αξιόπιστες <strong>εταιρείες ταχυμεταφορών</strong> για να δημιουργούν αυτόματα δελτία αποστολών και να έχουν τον απόλυτο έλεγχο των παραγγελιών, από την παραγγελία μέχρι την παράδοση.</p>
							</div>
						</div>
						<div className="row align-items-center mb-5">
							<div className="col-md-5 order-md-1">
								<GatsbyImage
									image={data.im2.childImageSharp.gatsbyImageData}
									alt=""
								/>
							</div>
							<div className="col-md-7">
								<p>
									Με τον τρόπο αυτό, οι σύγχρονες επιχειρήσεις ηλεκτρονικού εμπορίου έχουν τη δυνατότητα να κοινοποιούν τις λίστες προϊόντων/υπηρεσιών τους ή τους καταλόγους τους σε διαδικτυακές αγορές, αλλά και να <strong>εξασφαλίζουν την άμεση αποστολή, αποφεύγοντας λάθη και εξοικονομώντας πολύτιμο χρόνο</strong>.
									<br />
									Στόχος του οικοσυστήματος SYN της Think Plus είναι η ανάπτυξη ενός ολοκληρωμένου συστήματος διασύνδεσης καταστήματος ηλεκτρονικού εμπορίου με κάθε marketplace, <strong>ERP, Courier ή Locker Service της Ελλάδας</strong>, ανάλογα πάντα με τις ανάγκες και τους στόχους κάθε eCommerce επιχείρησης.
								</p>
							</div>
						</div>
						<div className="row align-items-center mb-5">
							<div className="col-md-5">
								<GatsbyImage
									image={data.im3.childImageSharp.gatsbyImageData}
									alt=""
								/>
							</div>
							<div className="col-md-7">
								<p>Ειδικότερα, ένα οικοσύστημα εργαλείων που μπορεί να διαχειρίζεται όλες τις σημαντικές δραστηριότητες που σχετίζονται με τις ηλεκτρονικές πωλήσεις σε αγορές, όπως η καταχώρηση όλων των προϊόντων/υπηρεσιών σε marketplaces, η <strong>αυτόματη ενημέρωση των προϊόντων (διαθεσιμότητα, τιμή κ.α.)</strong>, η παρακολούθηση και η διαχείριση των παραγγελιών ή διασύνδεση με ERP, ακόμα και η διασύνδεση με εταιρείες κούριερ ή locker services για πιο γρήγορη αποστολή-παράδοση.</p>
							</div>
						</div>
						<div className="text-align-center">
							<Link to="#contact_form_section" className="btn btn__theme--inverted">
								Eπικοινώνησε άμεσα!
							</Link>
						</div>
					</section>

					{/* Available integrations */}
					<section className="background__theme mb-5">
						<div className="container py-5">
							<h2 className="color--primary mb-5 text-align-center">
								Διαθέσιμες διασυνδέσεις:
							</h2>
							<div className="integrations-grid">
								<GatsbyImage
									image={data.skroutz.childImageSharp.gatsbyImageData}
									alt="Skroutz - XML for Shopify"
								/>
								<GatsbyImage
									image={data.glami.childImageSharp.gatsbyImageData}
									alt="Glami - XML for Shopify"
								/>
								<GatsbyImage
									image={data.shopflix.childImageSharp.gatsbyImageData}
									alt="Shopflix - XML for Shopify"
								/>
								<GatsbyImage
									image={data.galaxyStore.childImageSharp.gatsbyImageData}
									alt="Galaxy Store - XML for Shopify"
								/>
								<GatsbyImage
									image={data.softOne.childImageSharp.gatsbyImageData}
									alt="Softone - XML for Shopify"
								/>
								<GatsbyImage
									image={data.atlantis.childImageSharp.gatsbyImageData}
									alt="Atlantis - XML for Shopify"
								/>
								<GatsbyImage
									image={data.pylon.childImageSharp.gatsbyImageData}
									alt="Pylon - XML for Shopify"
								/>
								<GatsbyImage
									image={data.boxNow.childImageSharp.gatsbyImageData}
									alt="Box Now - XML for Shopify"
								/>
								<GatsbyImage
									image={data.easyMail.childImageSharp.gatsbyImageData}
									alt="Easy Mail - XML for Shopify"
								/>
								<GatsbyImage
									image={data.acs.childImageSharp.gatsbyImageData}
									alt="ACS - XML for Shopify"
								/>
								<GatsbyImage
									image={data.geniki.childImageSharp.gatsbyImageData}
									alt="Γενική Ταχυδρομική - XML for Shopify"
								/>
								<GatsbyImage
									image={data.speedex.childImageSharp.gatsbyImageData}
									alt="Speedex - XML for Shopify"
								/>
							</div>
						</div>
					</section>
					<section className="container py-5 text-align-center">
						<h2 className="color--primary">
							Έχεις VOUCHER;
							<br />
							Έχεις την πιο ολοκληρωμένη λύση από την Think Plus
						</h2>
						<Link to="#contact_form_section" className="btn btn__theme--inverted">
							Eπικοινώνησε άμεσα!
						</Link>
					</section>
					<ContactForm />
				</article>
			</Layout>
		</>
	);
};

export default PageShopifyApps;
